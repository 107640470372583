<template>
  <div class="form form-admin-currency">
    <v-form
      ref="form"
      class="pa-6"
      v-model="valid"
    >
      <Loading :value="loading" />
      <!-- {{props}} -->
      <div class="pa-0">
        <v-row v-if="items.length">
          <v-col cols="8">
            <v-text-field
              v-model="id"
              disabled
              :label="$t('INPUT.ID')"
              :placeholder="$t('INPUT.ID')"
              outlined
            />
          </v-col>

          <v-col cols="4">
            <v-select
            v-model="status"
            :items="[{text: $t('INPUT.STATUS_0'), value: 0}, {text: $t('INPUT.STATUS_1'), value: 1}]"
            :label="$t('INPUT.STATUS')"
            :placeholder="$t('INPUT.STATUS')"
            item-value="value"
            item-text="text"
            outlined
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-text-field
          v-model="title"
          :rules="[$rules.required, $rules.maxlength(255)]"
          :label="$t('INPUT.TITLE')"
          :placeholder="$t('INPUT.TITLE')"
          outlined
          required
        />
      </div>

      <div class="pa-0">
        <v-select
        v-model="country_id"
        :items="countryList"
        :label="$t('INPUT.COUNTRY')"
        :placeholder="$t('INPUT.COUNTRY')"
        item-value="id"
        item-text="title"
        :rules="[$rules.required]"
        required
        outlined
        />
      </div>

      <div class="pa-0 pb-2">
        <v-textarea
          v-model="description"
          :rules="[$rules.maxlength(1023)]"
          :label="$t('INPUT.DESCRIPTION')"
          :placeholder="$t('INPUT.DESCRIPTION')"
          outlined
        />
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="2" class="text-center pt-0 pb-0">
            <v-avatar size="60">
              <img :src="image_url || '/img/empty-image.png'" width="100%" >
            </v-avatar>
          </v-col>
          <v-col cols="10" class="pt-0 pb-0">
            <v-file-input
              v-if="!image_url"
              v-model="image_url"
              :label="$t('INPUT.UPLOAD_IMAGE')"
              :placeholder="$t('INPUT.UPLOAD_IMAGE')"
              prepend-icon=""
              prepend-inner-icon="mdi-camera"
              outlined
            />
          </v-col>
        </v-row>
      </div>
    </v-form>

    <v-divider />

    <div class="pa-4 secondary d-flex">
      <v-btn
        @click="reset"
        large
        color="primary--text"
      >
        <v-icon>mdi-backup-restore</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        color="accent"
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check-bold</v-icon>
        <span>{{$t(items.length ? 'COMMON.APPLY' : 'COMMON.CREATE')}}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'FormCurrencyCompact',
  components: {
    Loading
  },
  data: () => ({
    loading: false,
    valid: false,
    countryList: [],
    // form
    /*
    status
    title
    conversion_rate
    code
    zero_decimal
    */
    id: undefined,
    status: undefined,
    title: undefined,
    conversion_rate: undefined,
    code: undefined,
    zero_decimal: undefined,
  }),
  props: {
    props: {
      type: [Object, Boolean],
      default: false,
    },
    items: {
      type: [Array, Boolean],
      default: false,
    },
  },
  watch: {
    loading (n) {
      this.$emit('loading', n)
    },
    props (n) {
      this.assignItems(n)
    }
  },
  computed: {
  },
  mounted () {
    this.assignItems(this.items)
    this.loadCountryList()
  },
  methods: {

    loadCountryList () {
      this.$services.api_user.country.search({ where: { status: 1 }})
        .then(response => {
          this.countryList = response.data
        })
    },

    reset () {
      if (this.items.length === 1) {
        this.assignItems(this.items)
      } else {
        this.id = undefined
        this.status = undefined
        this.title = undefined
        this.conversion_rate = undefined
        this.code = undefined
        this.zero_decimal = undefined
      }
    },

    assignItems (items) {
      if (items.length === 1) {
        const data = items[0]
        this.id = data.id
        this.status = data.status
        this.title = data.title
        this.conversion_rate = data.conversion_rate
        this.code = data.code
        this.zero_decimal = data.zero_decimal

      }
    },

    validate () {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) return this.updateItem()
        return this.createItem()
      }
    },

    createItem () {
      // prepare values
      const values = {
        country_id: this.country_id,
        status: this.status,
        title: this.title,
        conversion_rate: this.conversion_rate,
        code: this.code,
        zero_decimal: this.zero_decimal,
      }
      const query = {
        values
      }
      this.loading = true
      this.$services.api_user.currency.create(query)
        .then(response => {
          this.$emit('update')
          this.$emit('select', [response.data])
        })
        .catch(response => {
          this.$store.dispatch('messenger/add', {type: 'error', code: response.code, message: response.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateItem () {
      // prepare values
      const oldValue = this.items[0] || {}
      const values = {}
      if (typeof this.status !== 'undefined' && this.status !== oldValue.status) values.status = this.status
      if (typeof this.title !== 'undefined' && this.title !== oldValue.title) values.title = this.title
      if (typeof this.conversion_rate !== 'undefined' && this.conversion_rate !== oldValue.conversion_rate) values.conversion_rate = this.conversion_rate
      if (typeof this.code !== 'undefined' && this.code !== oldValue.code) values.code = this.code
      if (typeof this.zero_decimal !== 'undefined' && this.zero_decimal !== oldValue.zero_decimal) values.zero_decimal = this.zero_decimal
      const query = {
        where: { id: this.items.map(v => v.id) },
        values
      }
      this.loading = true
      this.$services.api_user.currency.update(query)
        .then(response => {
          this.loading = false
          this.$emit('update')
        })
        .catch(response => {
          this.$store.dispatch('messenger/add', {type: 'error', code: response.code, message: response.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
